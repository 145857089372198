import { createI18n } from 'vue-i18n'
import zhCN from './zh-CN.json'
import en from './en.json'
import zhTW from './zh-TW.json'

const i18n = createI18n({
  // 如果本地有语言标识就采用本地，没有就根据浏览器语言默认标识显示语言
  locale:
    localStorage.getItem('locale') || navigator.language.slice(0, 2),
  globalInjection: true,
  legacy: false,
  messages: {
    'zh': zhCN,
    en,
    'zh-TW': zhTW,
  },
})

export default i18n

export const i18nInstance = i18n.global
