<script lang="ts" setup>
import { props } from './Props'

const emit = defineEmits(['update:modelValue', 'confirm', 'close'])

// Modal 方法调用传入 props 无法通过 emit 修改
// 组件显示的数据双向代理
const modelValue = computed({
  get: () => props.modelValue as unknown as boolean,
  set: (value: boolean) => emit('update:modelValue', value),
})

// 所以假如传入直接是一个 ref 则直接使用
const show = isRef(props.modelValue)
  ? props.modelValue
  : modelValue

// 假如初始化为 true , 切换状态让动画正常显示
if (show.value) {
  show.value = false
  nextTick(() => (show.value = true))
}

// 关闭事件, 调用 reject, 为了兼容模板上直接使用组件, 还要在调用一次 close 事件
function onClose() {
  props.reject && props.reject()
  emit('close')
  show.value = false
}

// 确定事件, 调用 resolve, 为了兼容模板上直接使用组件, 还要在调用一次 confirm 事件
function onConfirm() {
  props.resolve && props.resolve()
  emit('confirm')
  show.value = false
}

function closeModal() {
  console.log('props.hideFooter', props.hideFooter)
  if (props.hideFooter) {
    onClose()
  }
}
</script>

<template>
  <div>
    <teleport to="body">
      <transition mode="out-in" name="fade">
        <div v-show="show" class="modal" @click.self="closeModal">
          <div
            class="body" :style="{
              width: width == '100%' ? `${100}%` : `${width}rem`,
              height,
              borderRadius: radius,
            }"
          >
            <div class="modalHeader">
              <slot name="modalHeader">
                <IconFont type="iconhuabanbeifen7" style="color: white; font-size: 24px" />
                <div v-if="title" class="title">
                  {{ title }}
                </div>
                <div>
                  <slot name="closeButton">
                    <IconFont type="iconhuabanbeifen7" style="color: #969696; font-size: 24px" @click="onClose" />
                  </slot>
                </div>
              </slot>
            </div>
            <slot v-if="$slots.default" />
            <template v-else>
              {{ content }}
            </template>
            <div v-if="!hideFooter" class="modalFooter">
              <!-- <a-button @click="onClose" type="primary" class="cancelBtn">取消</a-button> -->
              <a-button type="primary" class="confirmBtn" @click="onConfirm">
                确定
              </a-button>
            </div>
          </div>
        </div>
      </transition>
    </teleport>
  </div>
</template>

<style scopde lang="less">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.modal {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 39;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal {
  .body {
    background: white;
    padding: 2rem 0rem;
    border-radius: 10px;

    .modalHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px 20px 20px;
      margin-bottom: 15px;
      border-bottom: 2px solid #edf0f5;

      & .title {
        color: #333333;
        font-size: 18px;
      }
    }

    .modalFooter {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 3rem;
      margin-right: 2rem;

      .confirmBtn {
        background-color: #ffaf00 !important;
        border-radius: 0.3rem;
        font-size: 1rem;
        color: white;
        border: none;
        width: 7rem;
        margin-left: 1rem;
      }

      .cancelBtn {
        background-color: #f6f6fc !important;
        border-radius: 0.3rem;
        font-size: 1rem;
        color: #838f9f;
        border: none;
        width: 7rem;
      }
    }
  }
}
</style>
