/*
 * @Descripttion:
 * @version:
 * @Author: jimmy
 * @Date: 2021-10-27 14:39:56
 * @LastEditors: jimmy
 * @LastEditTime: 2022-02-17 11:12:09
 */
/** 模态框固定 props 参数, 用于调用模态框成功|关闭|销毁 */
export const modalProps = {
  // 是否展示组件
  modelValue: Boolean,
  // 组件消失时(移除实例)
  vanish: Function,
  // 组件调用成功事件
  resolve: Function,
  // 组件调用失败事件
  reject: Function,
}

/** 组件内传入 props 参数, 用于模态框自定义功能 */
export const componentProps = {
  // 模态框标题
  title: String,
  // 模态框内容
  content: String,
  // 高度
  height: {
    type: String,
    default: 'auto',
  },
  // 宽度
  width: {
    type: String,
    default: '25',
  },
  hideFooter: {
    type: Boolean,
    default: false,
  },
  radius: {
    type: String,
    default: '10px',
  },
}

/** 组件内所有 Props 参数, 合并参数 */
export const props = { ...modalProps, ...componentProps }
