import { createApp } from 'vue'
import lazyPlugin from 'vue3-lazy'
import Antd from 'ant-design-vue'
import App from './App.vue'
import './utils/utils'
import {
  Button,
  Calendar,
  Checkbox,
  CheckboxGroup,
  DatetimePicker,
  Divider,
  Field,
  Picker,
  Popup,
} from 'vant'
import '@/styles/over.less'
import '@/styles/flex.less'
import '@/styles/distance.less'
import '@/styles/buttons.less'
import '@/styles/animation.less'
import '@/styles/container.less'
import '@/styles/fonts.less'
import MyModal from './components/Common/Modal/Index.vue'
import shot_img_bg from './assets/shot_img_bg.png'
import tinymce from 'tinymce'
import VueTinymce from '@packy-tang/vue-tinymce'
import 'animate.css'
import 'normalize.css'
import vueI18n from '@/i18n'
import * as $ from 'jquery'
import { createPinia } from 'pinia'
// import agconnect from '@agconnect/api';
import '@agconnect/instance'
// import * as dayjs from 'dayjs';
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import isYesterday from 'dayjs/plugin/isYesterday'
import timezone from 'dayjs/plugin/timezone'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import calendar from 'dayjs/plugin/calendar'
import '@surely-vue/table/dist/index.less'
import '@surely-vue/table/src/style/antdv.less'
import 'vue-final-modal/style.css'
import STable from '@surely-vue/table'
import 'virtual:uno.css'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { createVfm } from 'vue-final-modal'
import routerV2 from './router/router'
import IconFont from './components/Common/IconFont/IconFont'

dayjs.extend(advancedFormat)
dayjs.extend(isYesterday)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(calendar)

const baseSize = 20
const maxScaleClientWidth = 0

const vfm = createVfm()

function setRem() {
  const clientWidth = document.documentElement.clientWidth
  const scale = clientWidth / 1920
  document.documentElement.style.fontSize = `${baseSize * scale}px`
}

// setRem();
document.documentElement.style.fontSize = '12px'

window.onresize = function () {
  // setRem();
}
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
// pinia.use(
//   createPersistedState({
//     key: (id) => `__mediastorm_persisted__${id}`,
//     auto: true,
//   })
// );
const app = createApp(App)
app.config.globalProperties.$tinymce = tinymce
if (process.env.NODE_ENV === 'development') {
  app.config.performance = true
}

window.$ = $

// const agConnectConfig = {
//   agcgw: {
//     backurl: 'connect-drcn.hispace.hicloud.com',
//     url: 'connect-drcn.dbankcloud.cn',
//     websocketbackurl: 'connect-ws-drcn.hispace.dbankcloud.com',
//     websocketurl: 'connect-ws-drcn.hispace.dbankcloud.cn',
//   },
//   agcgw_all: {
//     CN: 'connect-drcn.dbankcloud.cn',
//     CN_back: 'connect-drcn.hispace.hicloud.com',
//     DE: 'connect-dre.dbankcloud.cn',
//     DE_back: 'connect-dre.hispace.hicloud.com',
//     RU: 'connect-drru.hispace.dbankcloud.ru',
//     RU_back: 'connect-drru.hispace.dbankcloud.cn',
//     SG: 'connect-dra.dbankcloud.cn',
//     SG_back: 'connect-dra.hispace.hicloud.com',
//   },
//   websocketgw_all: {
//     CN: 'connect-ws-drcn.hispace.dbankcloud.cn',
//     CN_back: 'connect-ws-drcn.hispace.dbankcloud.com',
//     DE: 'connect-ws-dre.hispace.dbankcloud.cn',
//     DE_back: 'connect-ws-dre.hispace.dbankcloud.com',
//     RU: 'connect-ws-drru.hispace.dbankcloud.ru',
//     RU_back: 'connect-ws-drru.hispace.dbankcloud.cn',
//     SG: 'connect-ws-dra.hispace.dbankcloud.cn',
//     SG_back: 'connect-ws-dra.hispace.dbankcloud.com',
//   },
//   client: {
//     cp_id: '260086000251297571',
//     product_id: '388421841221752506',
//     client_id: '1278073757776273856',
//     client_secret:
//       'DF64D6AA45EE6BE41A6A24EBF0534368BD189982C699CF6A968496B624FF8075',
//     project_id: '388421841221752506',
//     app_id: '172249065902788051',
//     api_key:
//       'DAEDAKaOKvjQnR+SrxKXKcaf0FB5pQ6L7MbG5VRJtapN6KB7uuBaY39M7gGEKxv852G/LQKFUgWfI3JDVmi+xWj+cnzhl1I/H54AEw==',
//   },
//   oauth_client: {
//     client_id: '109507019',
//     client_type: 7,
//   },
//   app_info: {
//     app_id: '172249065902788051',
//   },
//   service: {
//     analytics: {
//       collector_url:
//         'datacollector-drcn.dt.hicloud.com,datacollector-drcn.dt.dbankcloud.cn',
//       collector_url_ru:
//         'datacollector-drru.dt.dbankcloud.ru,datacollector-drru.dt.hicloud.com',
//       collector_url_sg:
//         'datacollector-dra.dt.hicloud.com,datacollector-dra.dt.dbankcloud.cn',
//       collector_url_de:
//         'datacollector-dre.dt.hicloud.com,datacollector-dre.dt.dbankcloud.cn',
//       collector_url_cn:
//         'datacollector-drcn.dt.hicloud.com,datacollector-drcn.dt.dbankcloud.cn',
//       resource_id: 'p1',
//       channel_id: '',
//     },
//     edukit: {
//       edu_url: 'edukit.cloud.huawei.com.cn',
//       dh_url: 'edukit.cloud.huawei.com.cn',
//     },
//     search: {
//       url: 'https://search-drcn.cloud.huawei.com',
//     },
//     cloudstorage: {
//       storage_url_sg_back:
//         'https://agc-storage-dra.cloud.huawei.asia',
//       storage_url_ru_back:
//         'https://agc-storage-drru.cloud.huawei.ru',
//       storage_url_ru: 'https://agc-storage-drru.cloud.huawei.ru',
//       storage_url_de_back: 'https://agc-storage-dre.cloud.huawei.eu',
//       storage_url_de: 'https://ops-dre.agcstorage.link',
//       storage_url: 'https://agc-storage-drcn.platform.dbankcloud.cn',
//       storage_url_sg: 'https://ops-dra.agcstorage.link',
//       storage_url_cn_back:
//         'https://agc-storage-drcn.cloud.huawei.com.cn',
//       storage_url_cn:
//         'https://agc-storage-drcn.platform.dbankcloud.cn',
//     },
//     ml: {
//       mlservice_url:
//         'ml-api-drcn.ai.dbankcloud.com,ml-api-drcn.ai.dbankcloud.cn',
//     },
//   },
//   region: 'CN',
//   configuration_version: '3.0',
// };

// 配置agconnect实例
// agconnect.instance().configInstance(agConnectConfig);
// 初始化分析实例
// let analytics = agconnect.analytics();
// const localUserId = localStorage.getItem('localUserId');
// if (localUserId) {
//   analytics.setUserId(localUserId);
// } else {
//   const userId = nanoid();
//   localStorage.setItem('localUserId', userId);
//   analytics.setUserId(userId);
// }

app.directive('focus', {
  /* ... */
  mounted: el => el.focus(),
})
app
  .use(lazyPlugin, {
    loading: shot_img_bg,
    error: shot_img_bg,
  })
  .use(pinia)
  .use(vueI18n)
  .use(Antd)
  .use(vfm)
  // .use(router)
  .use(routerV2)
  .use(VueTinymce)
  .use(Button)
  .use(Field)
  .use(Calendar)
  .use(DatetimePicker)
  .use(Popup)
  .use(Divider)
  .use(Checkbox)
  .use(CheckboxGroup)
  .use(Picker)
  .use(STable)
  .component('Modal', MyModal)
  .component('IconFont', IconFont)
  .mount('#app')
