<script setup lang="ts">
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn' // 导入本地化语言
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import { useRoute } from 'vue-router'
import { ModalsContainer } from 'vue-final-modal'
import MobileImage from '@/assets/mobile_logo.png'
import MobileBottom from '@/assets/mobile_bottom.png'
import UseH5 from '@/composables/UseH5'

dayjs.locale('zh-cn') // 使用本地化语言

const { isMobile, isFromIOS } = UseH5()
const route = useRoute()
const router = useRouter()
const isWx = ref(/MicroMessenger/i.test(window.navigator.userAgent))

function checkUserDevice() {
  if (
    navigator.userAgent.match(/Mobi/i)
    || navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/iPhone/i)
  ) {
    // 当前设备是移动设备
    isMobile.value = true
  }
  else {
    isMobile.value = false
  }
}

function checkFromMobile() {
  router.isReady().then(() => {
    if (route.query) {
      const jwt: string = route.query.token as any as string
      const id: string = route.query.id as any as string
      if (jwt && id) {
        localStorage.setItem('jwt', jwt)
        localStorage.setItem('sheetProjectId', id)
        isFromIOS.value = true
      }
    }
  })
}

onBeforeMount(() => {
  checkFromMobile()
})

onMounted(() => {
  checkUserDevice()
})
</script>

<template>
  <div v-if="isMobile && !isFromIOS && !isWx" class="mobile_view">
    <div style="text-align: center">
      <img class="mobile_image" :src="MobileImage">
      <div style="margin-top: 1.666rem">
        <p class="mobile_text">
          为了您的使用体验
        </p>
        <p class="mobile_text">
          iOS及iPad用户请下载移动端App
        </p>
      </div>
    </div>
    <img class="mobile_image_bottom" :src="MobileBottom">
  </div>
  <a-config-provider v-else :auto-insert-space-in-button="false" :locale="zhCN">
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" />
      </keep-alive>
    </router-view>
    <ModalsContainer />

    <!-- vue3.0配置 -->
    <!-- <div class="z-999 fixed left-1 top-1">fps: {{ fps }}</div> -->
    <!-- <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" v-if="$route.meta.keepAlive" />
      </keep-alive>
      <component :is="Component" v-if="!$route.meta.keepAlive" />
    </router-view> -->
  </a-config-provider>
</template>

<style lang="less">
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  height: 100%;
  background-color: #fff !important;
}

.header {
  background-color: #fff !important;
  //position: fixed;
  z-index: 1;
  width: 100%;
  display: flex;
  align-items: center;
}

.mainContent {
  overflow: auto !important;
  //height: 100vh !important;
  //width: calc(100% - 5.1rem) !important;
  //position: fixed;
  //top: 64px !important;
  //left: 6.8rem !important;
  //background: #f4f6f7 !important;
  //border: 2px solid #edf0f5 !important;
  //border-radius: 0.5rem !important;
  // padding: 1.2rem !important;
}

.sidebarView {
  overflow: auto !important;
  //height: 100vh !important;
  //position: fixed !important;
  //top: 64px !important;
  //left: 0 !important;
  background: #fdfdff !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  // margin-top: -1.5rem;
  //padding-top: 1rem;
}

.mobile_view {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.mobile_text {
  font-size: 1.166rem;
  font-weight: 600;
  color: #24252c;
}

.mobile_image {
  width: 6.66rem;
}

.mobile_image_bottom {
  width: 25.5rem;
  position: fixed;
  right: 0;
  bottom: 0;
}
</style>
