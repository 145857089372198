import { createRouter, createWebHashHistory } from 'vue-router'

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    // {
    //   path: "/",
    //   name: "/",
    //   component: async () => await import("../views/Maintenance.vue"),
    // },
    {
      path: '/login',
      name: 'login',
      redirect: { name: 'signIn' },
    },
    {
      path: '/signIn',
      name: 'signIn',
      component: async () => await import('../views/LoginViewV2.vue'),
    },
    {
      path: '/signInV2',
      name: 'signInV2',
      component: async () => await import('../views/LoginViewV2.vue'),
    },
    {
      path: '/test',
      name: 'test',
      component: async () => await import('../views/Test.vue'),
    },
    {
      path: '/joinProject/:url',
      name: 'joinProject',
      component: async () => await import('../views/JoinProject.vue'),
    },
    {
      path: '/joinProjectV2/:key',
      name: 'joinProjectV2',
      component: async () => await import('../views/JoinProjectV2.vue'),
    },
    {
      path: '/joinFail',
      name: 'joinFail',
      component: async () => await import('../views/JoinFail.vue'),
    },
    {
      path: '/Maintenance',
      name: 'maintenance',
      component: async () => await import('../views/Maintenance.vue'),
    },
    {
      path: '/feedback',
      name: 'feedback',
      component: async () => await import('../views/FeedBack.vue'),
      meta: {
        keepAlive: true,
      },
    },
    {
      path: '/exchange',
      name: 'exchange',
      component: async () => await import('../views/Exchange.vue'),
    },
    {
      path: '/',
      name: 'home',
      component: async () => await import('../views/HomeViewV2.vue'),
      meta: {
        keepAlive: true,
      },
      children: [
        // {
        //   path: '/',
        //   name: 'main',
        //   component: async () => await import('../views/Main.vue'),
        //   meta: {
        //     keepAlive: true,
        //   },
        // },
        {
          path: '/',
          name: 'mainV2',
          component: async () => await import('../views/MainViewV2.vue'),
          meta: {
            keepAlive: true,
          },
        },
        {
          path: 'inSpiration',
          name: 'inSpiration',
          component: async () => await import('../views/Inspiration.vue'),
          meta: {
            keepAlive: true,
          },
        },
        {
          path: 'inSpirationDetail/:id',
          name: 'inSpirationDetail',
          component: async () => await import('../views/InspirationDetail.vue'),
        },
        {
          path: 'contact',
          name: 'contact',
          component: async () => await import('../views/Contact.vue'),
          meta: {
            keepAlive: false,
          },
        },
        {
          path: 'contactV2',
          name: 'contactV2',
          component: async () => await import('../views/ContactV2.vue'),
          meta: {
            keepAlive: false,
          },
        },
        {
          path: 'detail/:title',
          name: 'detail',
          component: async () => await import('../views/ProjectDetail.vue'),
        },
        {
          path: 'storyboard',
          name: 'storyboard',
          component: async () => await import('../views/StoryBoard.vue'),
          meta: {
            keepAlive: false,
          },
        },
        {
          path: 'storyboardV2/:projectId',
          name: 'storyboardV2',
          component: async () => await import('../views/StoryboardV2.vue'),
          meta: {
            keepAlive: false,
          },
        },
        {
          path: 'editProjectSetting/:index',
          name: 'editProjectSetting',
          component: async () =>
            await import('../views/EditProjectSetting.vue'),
          meta: {
            keepAlive: false,
          },
        },
        {
          path: 'editProjectSettingV2/:templateId',
          name: 'editProjectSettingV2',
          component: async () =>
            await import('../views/EditProjectSettingV2.vue'),
          meta: {
            keepAlive: false,
          },
        },
        {
          path: 'editStoryBoardMap/:index',
          name: 'editStoryBoardMap',
          component: async () => await import('../views/EditStoryBoardMap.vue'),
          meta: {
            keepAlive: false,
          },
        },
        {
          path: 'editStoryBoardMapV2/:templateId',
          name: 'editStoryBoardMapV2',
          component: async () =>
            await import('../views/EditStoryBoardMapV2.vue'),
          meta: {
            keepAlive: false,
          },
        },
        {
          path: 'editShotPlanMap/:index',
          name: 'editShotPlanMap',
          component: async () => await import('../views/EditShotPlanMap.vue'),
          meta: {
            keepAlive: false,
          },
        },
        {
          path: 'editShotScheduleMapV2/:templateId',
          name: 'editShotScheduleMapV2',
          component: async () =>
            await import('../views/EditShotScheduleMapV2.vue'),
          meta: {
            keepAlive: false,
          },
        },
        {
          path: 'storyboardnew',
          name: 'storyboardnew',
          component: async () => await import('../views/StoryBoardNew.vue'),
          meta: {
            keepAlive: false,
          },
        },
        {
          path: 'storyboardnewV2',
          name: 'storyboardnewV2',
          component: async () => await import('../views/StoryBoardNewV2.vue'),
          meta: {
            keepAlive: false,
          },
        },
        {
          path: 'shotPlan',
          name: 'shotPlan',
          component: async () => await import('../views/ShotPlanNew.vue'),
          meta: {
            keepAlive: false,
          },
        },
        {
          path: 'shotPlanV2',
          name: 'shotPlanV2',
          component: async () => await import('../views/ShotPlanNewV2.vue'),
          meta: {
            keepAlive: false,
          },
        },
        {
          path: 'sheet',
          name: 'sheet',
          component: async () => await import('../views/Sheet.vue'),
          meta: {
            keepAlive: false,
          },
          beforeEnter: (to, from) => {
            // reject the navigation
            // const { myDigitalOrder } = useDigital();
            // return !!myDigitalOrder.value.length;
            return true
          },
        },
        {
          path: 'sheetV2',
          name: 'sheetV2',
          component: async () => await import('../views/SheetV2.vue'),
          meta: {
            keepAlive: false,
          },
          beforeEnter: (to, from) => {
            // reject the navigation
            // const { myDigitalOrder } = useDigital();
            // return !!myDigitalOrder.value.length;
            return true
          },
        },
        {
          path: 'createsheet',
          name: 'createsheet',
          component: async () => await import('../views/SheetCreate.vue'),
        },
        {
          path: 'newCreateSheet',
          name: 'newCreateSheet',
          component: async () => await import('../views/SheetCreateNew.vue'),
        },
        {
          path: 'callSheetV2/:id',
          name: 'callSheetV2',
          component: async () => await import('../views/CallSheetV2.vue'),
        },
        {
          path: 'exportNew/:from/:id?',
          name: 'exportNew',
          component: async () => await import('../views/ExportNew.vue'),
        },
        {
          path: 'exportNewV2/:from/:id?',
          name: 'exportNewV2',
          component: async () => await import('../views/ExportNewV2.vue'),
        },
      ],
    },
  ],
})

export default router
