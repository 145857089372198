import { ref } from 'vue'

const isMobile = ref(false)
const isFromIOS = ref(false)

export default () => {
  return {
    isMobile,
    isFromIOS,
  }
}
